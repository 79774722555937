import { AsyncPipe } from '@angular/common'
import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core'
import { MatDividerModule } from '@angular/material/divider'
import { MatIconModule } from '@angular/material/icon'
import { MatListModule } from '@angular/material/list'
import { MatMenuModule } from '@angular/material/menu'
import { MatToolbarModule } from '@angular/material/toolbar'
import { Router, RouterLink } from '@angular/router'
import { TranslocoModule } from '@ngneat/transloco'
import { Store } from '@ngrx/store'
import { Observable, Subscription } from 'rxjs'

import { UserService } from '~core/services'
import { toggleExpandSidebar, toggleMobileMenu } from '~core/store/ui/ui.actions'
import { ThemeMode } from '~core/store/ui/ui.reducer'
import { selectIsMobileSidebar, selectThemeMode } from '~core/store/ui/ui.selectors'
import { User } from '~features/user/models/user.model'
import { IconOutlined } from '~icons'

import { LogoComponent } from '../../components/logo/logo.component'
import { BoxComponent } from '../../ui/box/box.component'
import { ProjectSelectorComponent } from '../project-selector/project-selector.component'

interface UserMenuItem {
  callback?: () => void
  icon: IconOutlined
  labelPath: string
  path: string
}

@Component({
  selector: 'sb-app-topbar',
  template: `
    <mat-toolbar>
      <mat-toolbar-row>
        @if ({ value: isMobileSidebar$ | async }; as isMobileSidebar) {
          <sb-box direction="row" items="center" [shrink]="0">
            @if (!isMobileSidebar.value) {
              <mat-list-item (click)="onToggleExpandSidebar()">
                <mat-icon matListItemIcon class="pointer" svgIcon="menu" color="primary" />
              </mat-list-item>
            }
            @if (isMobileSidebar.value) {
              <mat-list-item (click)="onToggleMobileMenu()">
                <mat-icon matListItemIcon class="pointer" svgIcon="menu" color="primary" />
              </mat-list-item>
            }
            <a class="app-topbar-brand" routerLink="/">
              <sb-logo
                [height]="isMobileSidebar.value ? '20px' : '30px'"
                [color]="(themeMode$ | async) === 'dark' ? 'white' : 'primary'"
                [pictogram]="isMobileSidebar.value"
              />
            </a>
          </sb-box>
          <sb-box direction="row" items="center" [gap]="isMobileSidebar.value ? '10px' : '20px'">
            @if (user) {
              <sb-box grow="1">
                @if (showProjectSelector) {
                  <sb-project-selector />
                }
              </sb-box>
            }
            <!--        <sb-theme-mode-toggle />-->
            @if (user) {
              <mat-list-item [matMenuTriggerFor]="menu">
                <mat-icon matListItemIcon svgIcon="outlined:person" color="primary" />
              </mat-list-item>
            }
          </sb-box>
        }
      </mat-toolbar-row>
    </mat-toolbar>

    <mat-menu #menu="matMenu">
      @if (user) {
        <ng-container *transloco="let t; read: 'navigation'">
          @for (item of menuItems; track item) {
            <a mat-menu-item [routerLink]="item.path">
              <mat-icon [svgIcon]="item.icon"></mat-icon>
              <span>{{ t(item.labelPath) }}</span>
            </a>
          }
          @if (user.plan.capabilities.includes('sudo')) {
            <a mat-menu-item routerLink="/admin">
              <mat-icon svgIcon="admin_panel_settings"></mat-icon>
              <span>{{ t('Administration') }}</span>
            </a>
          }
          <button (click)="onClickSupport()" mat-menu-item>
            <mat-icon [svgIcon]="Icon.SupportAgent" />
            <span>{{ t('HelpDesk') }}</span>
          </button>
          <mat-divider></mat-divider>
          <button (click)="onLogout()" mat-menu-item>
            <mat-icon [svgIcon]="Icon.Logout" />
            <span>{{ t('Logout') }}</span>
          </button>
        </ng-container>
      }
    </mat-menu>
  `,
  styleUrls: ['./app-topbar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatToolbarModule,
    BoxComponent,
    MatListModule,
    MatIconModule,
    RouterLink,
    LogoComponent,
    ProjectSelectorComponent,
    MatMenuModule,
    TranslocoModule,
    MatDividerModule,
    AsyncPipe,
  ],
})
export class AppTopbarComponent implements OnInit, OnDestroy {
  isMobileSidebar$: Observable<boolean>
  menuItems: UserMenuItem[] = [
    {
      path: '/user/account',
      labelPath: 'Account',
      icon: IconOutlined.Person,
    },
    {
      path: '/integrations',
      labelPath: 'Integrations',
      icon: IconOutlined.MergeType,
    },
    {
      path: '/user/subscription',
      labelPath: 'subscription.Label',
      icon: IconOutlined.Autorenew,
    },
  ]
  @Input() showProjectSelector: boolean = false
  themeMode$: Observable<ThemeMode>
  user: User
  protected readonly Icon = IconOutlined
  private subscription: Subscription

  constructor(
    private store: Store,
    private router: Router,
    private userService: UserService,
  ) {}

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }

  ngOnInit(): void {
    this.isMobileSidebar$ = this.store.select(selectIsMobileSidebar)
    this.themeMode$ = this.store.select(selectThemeMode)
    this.subscription = this.userService.user.subscribe((user) => (this.user = user))
  }

  onClickSupport() {
    // Check if customerly exists
    if (typeof (<any>window).customerly !== 'undefined') {
      ;(<any>window).customerly.open()
    }
  }

  onLogout() {
    this.userService.purgeAuth()
    window.location.reload()
  }

  onToggleExpandSidebar() {
    this.store.dispatch(toggleExpandSidebar())
  }

  onToggleMobileMenu() {
    this.store.dispatch(toggleMobileMenu())
  }
}
