import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'
import { MatListModule } from '@angular/material/list'
import { MatMenuTrigger, MatMenuModule } from '@angular/material/menu'
import { Router } from '@angular/router'
import { TranslocoService, TranslocoModule } from '@ngneat/transloco'
import { combineLatestWith, Subscription } from 'rxjs'
import { switchMap } from 'rxjs/operators'

import { Helper, UserService } from '~core/services'
import { DialogService } from '~core/services/ui/dialog.service'
import { Project } from '~features/projects/models/project.model'
import { UpgradeDialogComponent } from '~features/subscription/components/upgrade-dialog/upgrade-dialog.component'
import { User } from '~features/user/models/user.model'

import { SearchBarComponent } from '../../components/filters/search-bar/search-bar.component'
import { ProjectItemComponent } from '../../components/project-item/project-item.component'
import { ClickStopPropagationDirective } from '../../directives/click-stop-propagation.directive'
import { AlertComponent } from '../../ui/alert/alert.component'
import { AvatarComponent } from '../../ui/avatar/avatar.component'
import { ButtonComponent } from '../../ui/button/button.component'
import { TextComponent } from '../../ui/text/text.component'

@Component({
  selector: 'sb-project-selector',
  templateUrl: './project-selector.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./project-selector.component.scss'],
  standalone: true,
  imports: [
    ButtonComponent,
    MatMenuModule,
    AvatarComponent,
    TranslocoModule,
    ClickStopPropagationDirective,
    ProjectItemComponent,
    SearchBarComponent,
    MatListModule,
    AlertComponent,
    MatIconModule,
    TextComponent,
  ],
})
export class ProjectSelectorComponent implements OnInit, OnDestroy {
  project: Project
  projectList: Project[]
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger
  user: User

  private subscriptions: Subscription[] = []

  constructor(
    public helper: Helper,
    private router: Router,
    private userService: UserService,
    private dialogService: DialogService,
    private translateService: TranslocoService,
  ) {}

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe())
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.userService.user.pipe(combineLatestWith(this.userService.currentProject$)).subscribe(([user, project]) => {
        this.user = user
        this.project = project
        this.projectList = user.projects.filter((p) => p._id !== project._id)
      }),
    )
  }

  onClickProjectSettigs() {
    this.router.navigate(['projects', this.project._id, 'settings']).then(() => this.trigger.closeMenu())
  }

  onNewProject() {
    if (this.user.projects.length >= this.user.plan.maxProjects) {
      this.openUpgradeDialog()
      return
    }

    this.router.navigate(['projects', 'new']).then(() => this.trigger.closeMenu())
  }

  onSearch(searchString: string): void {
    this.projectList = this.user.projects.filter((p) => {
      return p._id !== this.project._id && (!searchString || p.name.toLowerCase().includes(searchString.toLowerCase()))
    })
  }

  onSelectProject(project: Project) {
    this.router.navigate(['projects', project._id]).then(() => this.trigger.closeMenu())
  }

  private openUpgradeDialog() {
    this.subscriptions.push(
      this.translateService
        .selectTranslate(`user.subscription.plans.name.${this.user.plan.name}`)
        .pipe(
          switchMap((planName) =>
            this.translateService.selectTranslateObject('alerts', {
              'projectsLimit.content': {
                plan: planName,
                projects: this.user.plan.maxProjects,
              },
            }),
          ),
        )
        .subscribe((alerts) => {
          const data = {
            title: alerts.projectsLimit.title,
            content: alerts.projectsLimit.content,
          }
          this.dialogService.open(UpgradeDialogComponent, { data, width: '835px' })
        }),
    )
  }
}
