<sb-button variant="menu" [matMenuTriggerFor]="menu" #trigger="matMenuTrigger">
  <div class="flex items-center gap-x-2.5 overflow-hidden">
    <sb-avatar size="xs" [src]="project.picture" [title]="project.name" />
    <span class="text-truncate">{{ project.name }}</span>
  </div>
</sb-button>

<!-- Menu template -->
<mat-menu #menu="matMenu" class="project-selector-menu" xPosition="before">
  <ng-container *transloco="let t; read: 'projects'">
    <div class="project-selector" sbClickStopPropagation>
      <div class="selected-project">
        <sb-project-item [project]="project" avatarSize="lg" gap="md" iconSize="24px" />
        <div class="mt-4 flex justify-end">
          <sb-button variant="square" (sbClick)="onClickProjectSettigs()">{{ t("actions.ManageProject") }}</sb-button>
        </div>
      </div>
      <div class="project-list">
        <!-- Search bar -->
        @if (user.projects.length > 1) {
          <div class="mb-2.5 px-6">
            <sb-search-bar [placeholder]="t('ui.SearchProject')" (search)="onSearch($event)" />
          </div>
        }
        @if (user.projects.length > 1) {
          <mat-selection-list>
            @for (project of projectList; track project) {
              <mat-list-item (click)="onSelectProject(project)">
                <sb-project-item gap="lg" titleSize="labelLarge" [project]="project" />
              </mat-list-item>
            }
            @if (projectList.length === 0) {
              <mat-list-item disabled>
                <sb-alert variant="neutral">{{ t("info.NoSearchResults") }}</sb-alert>
              </mat-list-item>
            }
          </mat-selection-list>
        }
        <div class="add-project-container">
          <mat-selection-list class="add-project">
            <mat-list-item>
              <div class="flex items-center gap-6" (click)="onNewProject()">
                <div class="icon-container">
                  <mat-icon svgIcon="add"></mat-icon>
                </div>
                <sb-text variant="labelLarge" noMargin>{{ t("actions.AddAnotherProject") }}</sb-text>
              </div>
            </mat-list-item>
          </mat-selection-list>
        </div>
      </div>
    </div>
  </ng-container>
</mat-menu>
